<!-- ----------------------------------------------------------------------- -->
<!--
name    : WORK CATEGORY VIEW

type    : view

used by : none

uses    : worktype-list,
          header-view,
          main-container

route   : /catalog/workcategory/view/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="work-category-view">
    <header-view
      :title="catalogType + ' Category: ' + work_category.name"
      previous_page="/catalog"
      :allow_edit="true"
      :edit_url="'/catalog/workcategory/edit/' + uuid" />
    <main-container>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Name"
                v-model="work_category.name"
                hint=""
                disabled
                required></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Default Rate"
                v-show="!showSizeAttribute"
                v-model="work_category.default_rate"
                hint=""
                :key="default_rate_key"
                disabled
                required
                :prefix="currencySymbol"></v-text-field>
              <v-select
                disabled
                v-show="showSizeAttribute"
                id="pricing_method"
                v-model="work_category.pricing_method"
                :items="pricingMethodSelects"
                label="Size Attribute"></v-select>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-select
                disabled
                id="status"
                v-model="work_category.status"
                :items="statusSelects"
                label="Status"></v-select>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                label="Description"
                v-model="work_category.description"
                disabled></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <work-type-list
          class="work-type-list"
          v-if="work_category && work_category.uuid"
          :tenant_uuid="tenant_uuid"
          :workcategory_uuid="work_category.uuid" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </main-container>
  </div>
</template>

<script>
  // components
  import MainContainer from '@/components/main-container';
  import WorkTypeList from '@/components/catalog/workcategory/worktype/work-type-list.vue';
  import HeaderView from '@/components/header-view.vue';

  // mixins
  import Localization from '@/mixins/localization';

  // services
  import Users from '@/services/Users.service.js';

  export default {
    name: 'WorkCategoryView',
    components: {
      'work-type-list': WorkTypeList,
      'header-view': HeaderView,
      'main-container': MainContainer,
    },
    mixins: [Localization],
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        wc_uuid: '',
        statusSelects: [
          {
            text: 'Active',
            value: 'Active',
          },
          {
            text: 'Inactive',
            value: 'Inactive',
          },
        ],
        default_rate_key: 0,
        work_category: {},
        tenant_uuid: '',
      };
    },
    async created() {
      // console.log( "workcategoryview: ", this.uuid )
      this.tenant_uuid = this.$auth.userProfile.tenant_uuid;

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // load up the work category
      this.work_category = await Users.getWorkCategory(this.uuid, accessToken);
    },
    methods: {
      edit() {
        this.$router.push({
          name: 'WorkCategoryEdit',
          params: {
            uuid: this.uuid,
          },
        });
      },
      close() {
        this.$router.push({
          name: 'Catalog',
        });
      },
    },
    computed: {
      currencySymbol() {
        var symbol = this.$auth.tenantProfile.currency_symbol
          ? this.$auth.tenantProfile.currency_symbol
          : '$';
        return symbol;
      },
      pricingMethodSelects: function () {
        var selectPHC = [
          {
            text: 'HEIGHT',
            value: 'HEIGHT',
          },
          {
            text: 'DBH',
            value: 'DBH',
          },
        ];
        return selectPHC;
      },
      catalogType: function () {
        if (this.work_category.catalog == 'HOURLY') {
          return 'Hourly Rate';
        } else if (this.work_category.catalog == 'VARIABLE') {
          return 'Variable Rate';
        } else if (this.work_category.catalog == 'FLAT') {
          return 'Flat Rate';
        }
        return '';
      },
      showSizeAttribute: function () {
        if (this.work_category.catalog == 'VARIABLE') {
          return true;
        }
        return false;
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped></style>
