var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"work-type-create"},[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-form',{ref:"form",staticClass:"mt-2",attrs:{"id":"workTypeCreateForm"},on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.opendialog),callback:function ($$v) {_vm.opendialog=$$v},expression:"opendialog"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","text":""}},[_c('header-view',{attrs:{"dialog":"","previous_page":"NO_BACK_BUTTON","title":"Category Task","closeBtn":"","closeAction":_vm.cancel,"btnOptions":[
              {
                name: '',
                btnColor: 'white',
                icon: 'mdi-check',
                action: this.save,
              } ]}}),_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('v-text-field',{attrs:{"label":"Name *","hint":"","rules":_vm.validate_required,"required":""},model:{value:(_vm.worktype.name),callback:function ($$v) {_vm.$set(_vm.worktype, "name", $$v)},expression:"worktype.name"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('v-select',{attrs:{"id":"status","items":_vm.statusSelects,"label":"Active"},model:{value:(_vm.worktype.status),callback:function ($$v) {_vm.$set(_vm.worktype, "status", $$v)},expression:"worktype.status"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('v-text-field',{key:_vm.rate_key,attrs:{"label":"Rate *","rules":_vm.validate_money_amount,"prefix":_vm.currencySymbol},on:{"blur":function($event){return _vm.formatMoney({
                        object: 'worktype',
                        attribute: 'rate',
                        key: 'rate_key',
                      })}},model:{value:(_vm.worktype.rate),callback:function ($$v) {_vm.$set(_vm.worktype, "rate", $$v)},expression:"worktype.rate"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Description"},model:{value:(_vm.worktype.description),callback:function ($$v) {_vm.$set(_vm.worktype, "description", $$v)},expression:"worktype.description"}})],1)],1)],1),_c('small',[_vm._v("*indicates required field")])],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":5000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Item saved "),_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" Close ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }