<!-- ----------------------------------------------------------------------- -->
<!--
name    : WORK TYPE CREATE DIALOG

type    : component

used by : work-type-list

uses    : header-view

 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="work-type-create">
    <v-layout row justify-center>
      <v-form
        ref="form"
        @submit.prevent="save"
        class="mt-2"
        id="workTypeCreateForm">
        <v-dialog v-model="opendialog" width="500" persistent>
          <v-card color="grey lighten-4" min-width="350px" text>
            <header-view
              dialog
              previous_page="NO_BACK_BUTTON"
              title="Category Task"
              closeBtn
              :closeAction="cancel"
              :btnOptions="[
                {
                  name: '',
                  btnColor: 'white',
                  icon: 'mdi-check',
                  action: this.save,
                },
              ]" />
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md4>
                    <v-text-field
                      label="Name *"
                      v-model="worktype.name"
                      hint=""
                      :rules="validate_required"
                      required></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md4>
                    <v-select
                      id="status"
                      v-model="worktype.status"
                      :items="statusSelects"
                      label="Active"></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md4>
                    <v-text-field
                      label="Rate *"
                      v-model="worktype.rate"
                      :rules="validate_money_amount"
                      :prefix="currencySymbol"
                      :key="rate_key"
                      @blur="
                        formatMoney({
                          object: 'worktype',
                          attribute: 'rate',
                          key: 'rate_key',
                        })
                      "></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      label="Description"
                      v-model="worktype.description"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-form>
    </v-layout>
    <v-snackbar v-model="snackbar" :timeout="5000">
      Item saved
      <v-btn color="blue" text @click="snackbar = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  // components
  import HeaderView from '@/components/header-view';

  // mixins
  import Forms from '@/mixins/forms';
  import Localization from '@/mixins/localization';

  // services
  import Users from '@/services/Users.service.js';

  export default {
    name: 'WorkTypeCreateDialog',
    components: {
      'header-view': HeaderView,
    },
    mixins: [Forms, Localization],
    props: {
      open: {
        type: Boolean,
        default: false,
      },
      workcategory_uuid: {
        type: String,
        default: '',
      },
      selected_work_type: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        snackbar: false,
        worktask: {},
        opendialog: false,
        statusSelects: [
          {
            text: 'Active',
            value: 'Active',
          },
          {
            text: 'Inactive',
            value: 'Inactive',
          },
        ],
        rate_key: 0,
        worktype: {},
      };
    },
    computed: {
      currencySymbol() {
        var symbol = this.$auth.tenantProfile.currency_symbol
          ? this.$auth.tenantProfile.currency_symbol
          : '$';
        return symbol;
      },
    },
    watch: {
      open: function () {
        this.opendialog = this.open;
      },
      selected_work_type: function () {
        // console.log( "wt create selected_work_type changed" );
        this.worktype = this.selected_work_type;
      },
    },
    mounted() {
      this.worktype = this.selected_work_type;
    },
    methods: {
      async save() {
        if (!this.$refs.form.validate()) {
          return false;
        }
        this.worktype.tenant_uuid = this.$auth.userProfile.tenant_uuid;
        this.worktype.workcategory_uuid = this.workcategory_uuid;

        const res = await Users.createWorkType(
          this.worktype
          // unsecured route due to onboarding, no accesstoken
        );

        if (res) {
          this.$emit('save');
          this.snackbar = true;
        }
      },
      async cancel() {
        this.$emit('cancel');
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped></style>
