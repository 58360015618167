<!-- ----------------------------------------------------------------------- -->
<!--
name    : WORKTYPE LIST

type    : component

used by : AppWorkCategoryView

uses    : work-type-create-dialog,
          work-type-edit-dialog,
          table-status

 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="work-type-list">
    <v-container v-if="workcategory_uuid">
      <v-toolbar flat color="transparent" class="mt-1">
        <v-toolbar-title>
          <span class="headline"> Category Tasks </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-title>
          <v-btn class="button-primary px-2" @click="addWorkType()">
            <v-icon>mdi-plus</v-icon>
            NEW
          </v-btn>
        </v-toolbar-title>
      </v-toolbar>
      <div>
        <v-data-table
          :headers="headers"
          :items="workTypes"
          class="elevation-1"
          sort-by="name">
          <template v-slot:item="props">
            <tr @click="editWorkType(props.item)">
              <td>{{ props.item.name }}</td>
              <td>
                {{
                  currencySymbol + Number.parseFloat(props.item.rate).toFixed(2)
                }}
              </td>
              <td class="text-center">
                <table-status :status="props.item.status" />
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            There are no items in this group, click the NEW button to create
            one.
          </template>
        </v-data-table>
      </div>
    </v-container>
    <work-type-create-dialog
      class="worktype-create-dialog"
      :selected_work_type="workType"
      :workcategory_uuid="workcategory_uuid"
      :open="createDialog"
      @save="savedEvent()"
      @cancel="cancelEvent()" />
    <work-type-edit-dialog
      class="worktype-edit-dialog"
      :selected_work_type="workType"
      :workcategory_uuid="workcategory_uuid"
      :open="editDialog"
      @save="savedEvent()"
      @cancel="cancelEvent()" />
  </div>
</template>

<script>
  // mixins
  import Localization from '@/mixins/localization';

  // components
  import WorkTypeCreateDialog from '@/components/catalog/workcategory/worktype/work-type-create-dialog';
  import WorkTypeEditDialog from '@/components/catalog/workcategory/worktype/work-type-edit-dialog';
  import TableStatus from '@/components/table-status';

  // services
  import Users from '@/services/Users.service.js';

  export default {
    name: 'WorkTypeList',
    components: {
      'table-status': TableStatus,
      'work-type-create-dialog': WorkTypeCreateDialog,
      'work-type-edit-dialog': WorkTypeEditDialog,
    },
    mixins: [Localization],
    props: {
      workcategory_uuid: {
        type: String,
        default: '',
      },
      tenant_uuid: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        createDialog: false,
        viewDialog: false,
        editDialog: false,
        headers: [
          {
            text: 'Name',
            align: 'left',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Rate',
            value: 'rate',
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
          },
        ],
        workTypes: [],
        workType: {},
      };
    },
    computed: {
      currencySymbol() {
        var symbol = this.$auth.tenantProfile.currency_symbol
          ? this.$auth.tenantProfile.currency_symbol
          : '$';
        return symbol;
      },
    },
    watch: {
      async workcategory_uuid() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        var params = {
          tenant_uuid: this.tenant_uuid
            ? this.tenant_uuid
            : this.$auth.userProfile.tenant_uuid,
          workcategory_uuid: this.workcategory_uuid,
        };

        this.workTypes = await Users.getWorkTypes(params, accessToken);
      },
    },
    async mounted() {},
    async created() {
      // console.log( "workcategory_uuid", this.workcategory_uuid )
      if (!this.workcategory_uuid) {
        console.log('no work category uuid passed in');
        return;
      }

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      var params = {
        tenant_uuid: this.tenant_uuid
          ? this.tenant_uuid
          : this.$auth.userProfile.tenant_uuid,
        workcategory_uuid: this.workcategory_uuid,
      };

      this.workTypes = await Users.getWorkTypes(params, accessToken);

      this.workCategory = await Users.getWorkCategory(
        this.workcategory_uuid,
        accessToken
      );
    },
    methods: {
      addWorkType() {
        this.workType = Users.blankWorkType();
        // console.log( "Setting rate to: " + this.workCategory.default_rate )
        this.workType.name = '';
        this.workType.description = '';
        this.workType.rate = this.workCategory.default_rate;
        this.workType.workcategory_uuid = this.workCategory.uuid;
        this.workType.status = 'Active';
        this.createDialog = true;
      },
      viewWorkType(item) {
        this.workType = this.workTypes[this.workTypes.indexOf(item)];
        this.viewDialog = true;
      },
      editWorkType(item) {
        this.workType = this.workTypes[this.workTypes.indexOf(item)];
        this.editDialog = true;
      },
      async savedEvent() {
        this.editDialog = false;
        this.viewDialog = false;
        this.createDialog = false;
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        var params = {
          tenant_uuid: this.tenant_uuid
            ? this.tenant_uuid
            : this.$auth.userProfile.tenant_uuid,
          workcategory_uuid: this.workcategory_uuid,
        };

        this.workTypes = await Users.getWorkTypes(params, accessToken);
      },
      cancelEvent() {
        this.editDialog = false;
        this.viewDialog = false;
        this.createDialog = false;
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  #filter-select {
    height: 60px;
    width: 100px;
    margin: auto;
  }
</style>
